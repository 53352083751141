import React, { useState, useEffect } from 'react';
import { ref, onValue, remove } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Showsms() {
  const [tableData, setTableData] = useState({});
  const [selectedItems, setSelectedItems] = useState(new Set()); // Track selected items
  const [showPopup, setShowPopup] = useState(false); // Track if the popup should be shown
  const [popupMessage, setPopupMessage] = useState(''); // Store the message for the popup

  useEffect(() => {
    document.title = "Page";
  }, []);

  useEffect(() => {
    const dataRef = ref(dbRealtime, 'ReceivedSMS'); // Reference to the 'ReceivedSMS' path in your database

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setTableData(dataObj);
      } else {
        console.log('No data available.');
      }
    };

    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener(); // Unsubscribe the listener
    };
  }, []);

  const reversedTableData = Object.entries(tableData).reverse();

  const handleSelect = (key) => {
    setSelectedItems(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key)) {
        newSelected.delete(key); // Deselect the item if already selected
      } else {
        newSelected.add(key); // Select the item
      }
      return newSelected;
    });
  };

  const handleDelete = (key) => {
    const itemRef = ref(dbRealtime, `ReceivedSMS/${key}`);
    remove(itemRef)
      .then(() => {
        console.log(`Item with key ${key} deleted successfully.`);
        setPopupMessage('Item deleted successfully');
        setShowPopup(true);
        // Optionally remove the item from the local state to reflect the changes
        setTableData(prevData => {
          const newData = { ...prevData };
          delete newData[key];
          return newData;
        });
      })
      .catch((error) => {
        console.error("Error deleting item: ", error);
        setPopupMessage('Error deleting item');
        setShowPopup(true);
      });
  };

  const handleBulkDelete = () => {
    selectedItems.forEach(key => {
      const itemRef = ref(dbRealtime, `ReceivedSMS/${key}`);
      remove(itemRef)
        .then(() => {
          console.log(`Item with key ${key} deleted successfully.`);
          setPopupMessage('Selected items deleted successfully');
          setShowPopup(true);
        })
        .catch((error) => {
          console.error("Error deleting item: ", error);
          setPopupMessage('Error deleting selected items');
          setShowPopup(true);
        });
    });
    setSelectedItems(new Set()); // Clear selected items after deletion
  };

  const handleSelectAll = () => {
    if (selectedItems.size === reversedTableData.length) {
      setSelectedItems(new Set()); // Deselect all if everything is selected
    } else {
      const allKeys = reversedTableData.map(([key]) => key);
      setSelectedItems(new Set(allKeys)); // Select all items
    }
  };

  return (
    <div>
      <Navbar />
      <h1 style={{ textAlign: 'center', marginTop: '-5px', fontSize: '25px' }}>SMS</h1>
      <p style={{ textAlign: 'center', color: 'red', padding: '0px', marginTop: '-20px' }}>show here</p>

      {/* Show delete button at the top if at least one item is selected */}
      {selectedItems.size > 0 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button onClick={handleBulkDelete} style={{ backgroundColor: 'red', color: 'white' }}>
            Delete Selected
          </button>
        </div>
      )}
{/* "Select All" Button */}
{selectedItems.size > 0 && reversedTableData.length > 0 && (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <button onClick={handleSelectAll} style={{ backgroundColor: 'blue', color: 'white' }}>
      {selectedItems.size === reversedTableData.length ? 'Deselect All' : 'Select All'}
    </button>
  </div>
)}


      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {reversedTableData.map(([key, value], index) => (
          <div key={index} className='box' style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px' }}>
            {/* Checkbox for selection */}
            <input 
              type="checkbox" 
              checked={selectedItems.has(key)} 
              onChange={() => handleSelect(key)} 
            />
            <span style={{ marginLeft: '10px' }}>Select</span>
           
            {Object.entries(value).map(([dataKey, dataValue], dataIndex) => (
              <p key={dataIndex}>
                {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
              </p>
            ))}
          </div>
        ))}
      </div>

      {/* Popup Message */}
      {showPopup && (
        <div className="popup" style={{ textAlign: 'center', padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', marginTop: '20px', borderRadius: '5px' }}>
          <p>{popupMessage}</p>
          <button onClick={() => setShowPopup(false)} style={{ backgroundColor: '#155724', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px' }}>
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default Showsms;
