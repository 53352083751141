import React, { useState, useEffect, useCallback } from 'react';
import { ref, onValue, remove, query, limitToLast, startAt, orderByKey } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Showsms() {
  const [tableData, setTableData] = useState([]); // Use an array instead of an object for data
  const [selectedItems, setSelectedItems] = useState(new Set()); // Track selected items
  const [showPopup, setShowPopup] = useState(false); // Track if the popup should be shown
  const [popupMessage, setPopupMessage] = useState(''); // Store the message for the popup
  const [isLoading, setIsLoading] = useState(false); // Track if data is being loaded
  const [lastLoadedKey, setLastLoadedKey] = useState(null); // Track the last loaded key for pagination

  // Load data from Firebase
  const loadData = useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);
    const dataRef = ref(dbRealtime, 'ReceivedSMS');
    let queryRef = dataRef;

    // Load more items if lastLoadedKey exists
    if (lastLoadedKey) {
      queryRef = query(dataRef, orderByKey(), limitToLast(20), startAt(lastLoadedKey)); // Fetch last 20 items starting from the last key
    } else {
      queryRef = query(dataRef, orderByKey(), limitToLast(20)); // Fetch the last 20 items
    }

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        const newData = Object.entries(dataObj).map(([key, value]) => ({ key, ...value })); // Convert object to array with keys

        setTableData((prevData) => {
          const updatedData = [...newData, ...prevData]; // Prepend new data to existing data
          return updatedData; // New data comes at the top, which will show the latest entries first
        });

        // Update the lastLoadedKey
        const keys = Object.keys(dataObj);
        if (keys.length > 0) {
          setLastLoadedKey(keys[keys.length - 1]); // Update to the last key of the current batch
        }
      } else {
        console.log('No data available.');
      }
      setIsLoading(false);
    };

    onValue(queryRef, handleDataChange);
  }, [isLoading, lastLoadedKey]);

  useEffect(() => {
    loadData(); // Load initial data on mount
  }, [loadData]);

  const handleSelect = (key) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key)) {
        newSelected.delete(key); // Deselect the item if already selected
      } else {
        newSelected.add(key); // Select the item
      }
      return newSelected;
    });
  };

  const handleDelete = (key) => {
    const itemRef = ref(dbRealtime, `ReceivedSMS/${key}`);
    remove(itemRef)
      .then(() => {
        console.log(`Item with key ${key} deleted successfully.`);
        setPopupMessage('Item deleted successfully');
        setShowPopup(true);
        // Optionally remove the item from the local state to reflect the changes
        setTableData((prevData) => prevData.filter((item) => item.key !== key));
      })
      .catch((error) => {
        console.error("Error deleting item: ", error);
        setPopupMessage('Error deleting item');
        setShowPopup(true);
      });
  };

  const handleBulkDelete = () => {
    selectedItems.forEach((key) => {
      const itemRef = ref(dbRealtime, `ReceivedSMS/${key}`);
      remove(itemRef)
        .then(() => {
          console.log(`Item with key ${key} deleted successfully.`);
          setPopupMessage('Selected items deleted successfully');
          setShowPopup(true);
        })
        .catch((error) => {
          console.error("Error deleting item: ", error);
          setPopupMessage('Error deleting selected items');
          setShowPopup(true);
        });
    });
    setSelectedItems(new Set()); // Clear selected items after deletion
  };

  const handleSelectAll = () => {
    if (selectedItems.size === tableData.length) {
      setSelectedItems(new Set()); // Deselect all if everything is selected
    } else {
      const allKeys = tableData.map((item) => item.key);
      setSelectedItems(new Set(allKeys)); // Select all items
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !isLoading) {
      loadData(); // Load more data when user scrolls to the bottom
    }
  };

  return (
    <div onScroll={handleScroll} style={{ height: '80vh', overflowY: 'auto' }}>
      <Navbar />
      <h1 style={{ textAlign: 'center', marginTop: '-5px', fontSize: '25px' }}>SMS</h1>
      <p style={{ textAlign: 'center', color: 'red', padding: '0px', marginTop: '-20px' }}>show here</p>

      {/* Show delete button at the top if at least one item is selected */}
      {selectedItems.size > 0 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button onClick={handleBulkDelete} style={{ backgroundColor: 'red', color: 'white' }}>
            Delete Selected
          </button>
        </div>
      )}

      {/* "Select All" Button */}
      {selectedItems.size > 0 && tableData.length > 0 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button onClick={handleSelectAll} style={{ backgroundColor: 'blue', color: 'white' }}>
            {selectedItems.size === tableData.length ? 'Deselect All' : 'Select All'}
          </button>
        </div>
      )}

      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {tableData.map((item, index) => (
          <div key={index} className='box' style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px' }}>
            {/* Checkbox for selection */}
            <input
              type="checkbox"
              checked={selectedItems.has(item.key)}
              onChange={() => handleSelect(item.key)}
            />
            <span style={{ marginLeft: '10px' }}>Select</span>
            {Object.entries(item).map(([dataKey, dataValue], dataIndex) => {
              // Skip the 'key' property, which is the Firebase key
              if (dataKey === 'key') return null;
              return (
                <p key={dataIndex}>
                  {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
                </p>
              );
            })}
          </div>
        ))}
      </div>

      {/* Popup Message */}
      {showPopup && (
        <div className="popup" style={{ textAlign: 'center', padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', marginTop: '20px', borderRadius: '5px' }}>
          <p>{popupMessage}</p>
          <button onClick={() => setShowPopup(false)} style={{ backgroundColor: '#155724', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px' }}>
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default Showsms;
